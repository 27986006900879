export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCjp0LSHXfk4sBrTP0HKH9-nnTWVGc8EDM",
    authDomain: "make-up-c7243.firebaseapp.com",
    databaseURL: "https://make-up-c7243-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "make-up-c7243",
    storageBucket: "make-up-c7243.appspot.com",
    messagingSenderId: "295327620414",
    appId: "1:295327620414:web:157c843fc40f32ebfd206f",
    measurementId: "G-SBE2T87MV8"
  },
};
